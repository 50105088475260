import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby-plugin-react-intl';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import get from 'lodash/get';

import * as styled from './styles';

const locales = {
  'en-US': require('date-fns/locale/en-US'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function EventCalendar(props) {
  function linkToEvent(e) {
    navigate(get(e, 'slug.current'));
  }

  return (
    <div>
      <styled.GlobalStyle />
      <Calendar
        date={props.date}
        localizer={localizer}
        events={props.events}
        startAccessor="startTime"
        endAccessor="endTime"
        drilldownView="null"
        views={['month']}
        onNavigate={() => {}}
        onSelectEvent={linkToEvent}
      />
    </div>
  );
}

EventCalendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      slug: PropTypes.object.isRequired,
      startTime: PropTypes.instanceOf(Date).isRequired,
      endTime: PropTypes.instanceOf(Date).isRequired,
      allDay: PropTypes.bool,
    }).isRequired,
  ).isRequired,
};

export default EventCalendar;
