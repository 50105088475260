import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-react-intl';
import { css } from 'styled-components';
import 'styled-components/macro';

import colors from '@utils/colors';
import { isExternalUrl } from '@utils/string';
import { Backdrop, Inner } from './styles';

class Button extends Component {
  static propTypes = {
    appearance: PropTypes.oneOf([
      'blue',
      'yellow',
      'orange',
      'purple',
      'green',
    ]),
    className: PropTypes.string,
    fill: PropTypes.bool,
    href: PropTypes.string,
    isDisabled: PropTypes.bool,
    isCentered: PropTypes.bool,
    isIconOnly: PropTypes.bool,
    isLoading: PropTypes.bool,
    onBlur: PropTypes.func,
    onClick: PropTypes.func,
    type: PropTypes.oneOf(['button', 'submit']),
    children: PropTypes.node,
  };

  static defaultProps = {
    appearance: 'blue',
    className: '',
    fill: false,
    href: '',
    isDisabled: false,
    isIconOnly: false,
    isLoading: false,
    isCentered: false,
    onBlur: null,
    onClick: null,
    type: 'button',
  };

  getAppearanceStyles = () => {
    const { appearance, fill } = this.props;

    switch (appearance) {
      case 'blue':
        return css`
          color: ${colors.B500};
          border-color: ${colors.B500};

          &:hover {
            background-color: ${colors.B400};
          }

          ${fill &&
          css`
            background-color: ${colors.B100};

            & + svg {
              fill: ${colors.B100};
            }
          `}
        `;
      case 'yellow':
        return css`
          color: ${colors.Y500};
          border-color: ${colors.Y500};

          &:hover {
            background-color: ${colors.B400};
          }

          ${fill &&
          css`
            background-color: ${colors.Y100};

            & + svg {
              fill: ${colors.Y100};
            }
          `}
        `;
      case 'orange':
        return css`
          color: ${colors.O500};
          border-color: ${colors.O500};

          &:hover {
            background-color: ${colors.B400};
          }

          ${fill &&
          css`
            background-color: ${colors.O100};

            & + svg {
              fill: ${colors.O100};
            }
          `}
        `;
      case 'purple':
        return css`
          color: ${colors.P500};
          border-color: ${colors.P500};

          &:hover {
            background-color: ${colors.B400};
          }

          ${fill &&
          css`
            background-color: ${colors.P100};

            & + svg {
              fill: ${colors.P100};
            }
          `}
        `;
      case 'green':
        return css`
          color: ${colors.G500};
          border-color: ${colors.G500};

          &:hover {
            background-color: ${colors.B400};
          }

          ${fill &&
          css`
            background-color: ${colors.G100};

            & + svg {
              fill: ${colors.G100};
            }
          `}
        `;

      // no default
    }
  };

  render() {
    const {
      ariaLabel,
      className,
      href,
      isDisabled,
      isFullWidth,
      isIconOnly,
      isCentered,
      isLoading,
      onBlur,
      onClick,
      rel,
      target,
      type,
      children,
    } = this.props;

    const buttonStyles = css`
      display: inline-flex;
      position: relative;
      width: ${isFullWidth ? '100%' : 'auto'};
      margin: 0;
      padding: 0 5px 0 3px;
      text-decoration: none;
      appearance: none;
      background-color: transparent;
      border: 0;
      box-shadow: 0;
      cursor: pointer;
      outline: 0;
      user-select: none;

      ${
        (isDisabled || isLoading) &&
        css`
          pointer-events: none;
          opacity: 0.25;
        `
      }

      ${
        (isCentered) &&
        css`
          display: block;
          margin: 0 auto;
        `
      }

    `;

    const props = {
      'aria-label': ariaLabel,
      'aria-disabled': isDisabled,
      disabled: isDisabled,
      onBlur,
      onClick,
      type,
    };

    if (href) {
      if (isExternalUrl(href)) {
        props.href = href;
        props.rel = rel;
        props.target = target;
      } else {
        props.to = href;
      }
    }

    const inner = (
      <>
        <Inner className={className} isFullWidth={isFullWidth} isIconOnly={isIconOnly} css={this.getAppearanceStyles()}>
          {children}
        </Inner>
        <Backdrop
          viewBox="0 0 214 57"
          xmlns="http://www.w3.org/2000/svg"
          fill="#fff"
          preserveAspectRatio="none"
        >
          <path d="M210.332 46.948C211.104 40.8527 209.963 34.2269 210.332 28.1282C210.819 20.368 214.645 13.3414 213.906 5.57332C213.815 4.67032 213.653 2.02564 207.031 1.56736C205.247 1.44332 203.38 1.35949 201.474 1.31791C186.202 0.979278 170.955 0.527775 155.573 0.437474C115.632 0.201563 81.034 0.111263 41.0863 0.337015L25.3147 0.647423C19.0243 0.609045 10.9375 -0.235266 6.44334 0.534547C1.19048 1.43755 3.84932 3.06635 3.66125 4.34636C2.88954 10.4417 4.0374 22.1875 3.66125 28.2851C3.18137 36.0453 -0.644791 43.0718 0.0944977 50.8411C0.178802 51.7441 0.340942 54.3876 6.96863 54.8459C8.75005 54.9703 10.6156 55.0545 12.5198 55.0965C27.792 55.4351 43.0383 55.8866 58.4207 55.9758C98.362 56.2128 132.96 56.302 172.907 56.0774C180.177 56.0367 187.109 55.513 194.126 55.1744C207.965 54.5073 209.69 53.8198 209.69 51.3253L210.332 46.948Z" />
        </Backdrop>
      </>
    );

    if (isDisabled || isLoading) {
      return (
        <span css={buttonStyles} {...props}>
          {inner}
        </span>
      );
    }

    if (!href) {
      return (
        <button css={buttonStyles} {...props}>
          {inner}
        </button>
      );
    }

    if (isExternalUrl(href)) {
      return (
        <a css={buttonStyles} {...props}>
          {inner}
        </a>
      );
    }

    return (
      <Link css={buttonStyles} {...props}>
        {inner}
      </Link>
    );
  }
}

export default Button;
