import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import colors from '@utils/colors';
import fonts from '@utils/fonts';
import { Check } from '@components/Icon';

const NativeCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  margin: 0;
  height: 16px;
  width: 16px;
  cursor: pointer;
`;

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 18px;
  width: 18px;
  background-color: ${(props) => (props.checked ? colors.B500 : colors.N0)};
  border: 2px solid ${(props) => props.checked ? colors.B500 : colors.B100};
  border-radius: ${(props) => props.round ? '9px' : '3px'};
  cursor: pointer;
`;

const StyledIcon = styled(Check)`
  fill: ${(props) => (props.checked ? colors.N0 : 'transparent')};
  height: 10px;
  pointer-events: none;
  transform: translateY(0.5px);
`;

const StyledLabel = styled.div`
  font-family: ${props => fonts(props.theme.locale).SANS};
  margin-left: 12px;
`;

const StyledWrapper = styled.label`
  display: flex;
  align-items: center;
`;

const Checkbox = ({ checked, label, name, onChange, round }) => {
  return (
    <StyledWrapper>
      <StyledCheckbox checked={checked} round={round}>
        <NativeCheckbox checked={checked} name={name} onChange={onChange} />
        <StyledIcon checked={checked} name="check" />
      </StyledCheckbox>
      <StyledLabel>{label}</StyledLabel>
    </StyledWrapper>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default Checkbox;
