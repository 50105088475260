import React from 'react';
import styled from 'styled-components';
import { useIntl } from '@utils/localize';

import colors from '@utils/colors';
import { useLocalFormat } from '@utils/localize';
import { mq } from '@utils/responsive';
import Button from '@components/Button';
import { Grid, List } from '@components/Icon';
import NavButton from '@components/NavButton';
import { NavSpacer } from '@components/Navigation';
import { Heading } from '@components/type';

const Actions = styled.div`
  display: flex;
  align-items: center;
  margin-right: 32px;

  & > *:first-child {
    margin-right: 36px;
  }
`;

const Icon = styled.span`
  display: inline-flex;
  align-items: center;
  height: 100%;

  ${mq.gtmd`
    display: none;
  `}
`;

const Month = styled.div`
  display: flex;
  align-items: center;

  ${mq.ltsm`
    justify-content: space-between;
  `}
`;

const Span = styled.span`
  display: none;

  ${mq.gtmd`
    display: inline;
  `}
`;

const Toggles = styled.div`
  display: flex;
  margin-top: 16px;

  ${mq.gtmd`
    margin-top: 0;
  `}

  ${mq.ltsm`
    & > button {
      width: 100%;

      & > div {
        width: 100%;
      }
    }
  `}

  & > button:first-of-type {
    margin-right: -8px;

    & > div {
      background-color: transparent;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  & > button:last-of-type {
    margin-left: -3px;

    & > div {
      background-color: transparent;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  margin-bottom: 24px;
  color: ${colors.B500};

  ${mq.gtmd`
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 40px;
    padding-top: 40px;
  `}
`;

function CalendarHeader({
  date,
  layout,
  onBack,
  onChangeLayout,
  onPrevMonth,
  onNextMonth,
  showingResults,
}) {
  const intl = useIntl();
  const localFormat = useLocalFormat();

  return (
    <>
      <NavSpacer />
      <Wrapper>
        <Month>
          {!showingResults && (
            <Actions>
              <NavButton
                direction="prev"
                onClick={onPrevMonth}
                colorTheme="yellow"
              />
              <NavButton
                direction="next"
                onClick={onNextMonth}
                colorTheme="yellow"
              />
            </Actions>
          )}
          <Heading level={100}>
            {showingResults
              ? intl.messages['calendar.results']
              : localFormat(new Date(date), 'MMMM yyyy')}
          </Heading>
        </Month>
        {showingResults ? (
          <Button appearance="blue" onClick={onBack}>
            {intl.messages['calendar.back']}
          </Button>
        ) : (
          <Toggles>
            <Button
              appearance="blue"
              fill={layout === 'list'}
              onClick={() => {
                onChangeLayout('list');
              }}
            >
              <Span>{intl.messages['calendar.listView']}</Span>
              <Icon>
                <List />
              </Icon>
            </Button>
            <Button
              appearance="blue"
              fill={layout === 'calendar'}
              onClick={() => {
                onChangeLayout('calendar');
              }}
            >
              <Span>{intl.messages['calendar.calendarView']}</Span>
              <Icon>
                <Grid />
              </Icon>
            </Button>
          </Toggles>
        )}
      </Wrapper>
    </>
  );
}

export default CalendarHeader;
