/* eslint-disable quotes */

import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';

import { dateLocales, useIntl, useLocalFormat } from '@utils/localize';
import useTranslationContext from '@utils/useTranslations';

export function useDateString() {
  const availableTranslations = useTranslationContext();
  const localFormat = useLocalFormat();
  const intl = useIntl();

  return (startDate, endDate) => {
    const options = {};

    if (intl.locale !== 'en' && availableTranslations && availableTranslations[intl.locale]) {
      options.locale = dateLocales[intl.locale];
    }

    if (isSameDay(startDate, endDate)) {
      return `${localFormat(startDate, 'h:mm a ', options)} - ${localFormat(
        endDate,
        "h:mm a 'on' MMMM d, yyyy",
      )}`;
    }

    return `${localFormat(startDate, "h:mm a 'on' MMMM d, yyyy")} - ${localFormat(
      endDate,
      "h:mm a 'on' MMMM d, yyyy",
    )}`;
  };
}

export function useTimeString() {
  const availableTranslations = useTranslationContext();
  const intl = useIntl();

  return (startDate, endDate) => {
    const options = {};

    if (intl.locale !== 'en' && availableTranslations && availableTranslations[intl.locale]) {
      options.locale = dateLocales[intl.locale];
    }

    if (isSameDay(startDate, endDate)) {
      return `${format(startDate, 'h:mm a ', options)} - ${format(
        endDate,
        'h:mm a',
        options,
      )}`;
    }

    return '';
  };
}

export function isExternalUrl(url) {
  if (!url) {
    return false;
  }

  return url.includes('http://') || url.includes('https://') || url.includes('mailto');
}
