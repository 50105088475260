import React from 'react';
import styled, { css } from 'styled-components';

import colors from '@utils/colors';
import { useBreakpoint } from '@utils/responsive';
import { ArrowLeft as UnstyledArrowLeft, ArrowRight as UnstyledArrowRight } from '@components/Icon';
import { BlobOne, BlobTwo } from '@components/vector';

const ArrowLeft = styled(UnstyledArrowLeft)`
  position: absolute;
  transform: translateX(40%);
  transition: transform 150ms ease;
`;

const ArrowRight = styled(UnstyledArrowRight)`
  position: absolute;
  transform: translateX(-40%);
  transition: transform 150ms ease;
`;

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  padding: 0;
  appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0;

  &:hover ${ArrowLeft} {
    transform: translateX(calc(40% - 10px));
  }

  &:hover ${ArrowRight} {
    transform: translateX(calc(-40% + 10px));
  }

  ${props => props.button === 'small' && css`
    height: 32px;
    width: 32px;
  `}

  ${props => props.button === 'regular' && css`
    height: 48px;
    width: 48px;
  `}

  ${props => props.button === 'large' && css`
    height: 72px;
    width: 72px;
  `}
`;

function NavButton({ direction, onClick, size, sizes, theme }) {
  const responsiveSize = useBreakpoint(sizes);
  const buttonSize = size || responsiveSize || 'regular';

  const svgSizes = {
    small: {
      arrow: 32,
      blob: 40,
    },
    regular: {
      arrow: 40,
      blob: 48,
    },
    large: {
      arrow: 54,
      blob: 72,
    },
  };

  const directions = {
    prev: (
      <>
        <BlobOne color={getThemeStyles(theme).button} size={svgSizes[buttonSize].blob} />
        <ArrowLeft color={getThemeStyles(theme).text} size={svgSizes[buttonSize].arrow} />
      </>
    ),
    next: (
      <>
        <BlobTwo color={getThemeStyles(theme).button} size={svgSizes[buttonSize].blob} />
        <ArrowRight color={getThemeStyles(theme).text} size={svgSizes[buttonSize].arrow} />
      </>
    ),
  };

  return (
    <Wrapper buttonSize={buttonSize} onClick={onClick} aria-label={direction}>
      {directions[direction]}
    </Wrapper>
  );
}

export default NavButton;

function getThemeStyles(theme) {
  switch (theme) {
    case 'blue':
      return {
        button: colors.B200,
        text: colors.B500,
      };
    case 'green':
      return {
        button: colors.G200,
        text: colors.G500,
      };
    case 'orange':
      return {
        button: colors.O200,
        text: colors.O500,
      };
    case 'purple':
      return {
        button: colors.P200,
        text: colors.P500,
      };
    default:
      return {
        button: colors.Y500,
        text: colors.B500,
      };
  }
}
