import styled from 'styled-components';

import colors from '@utils/colors';
import fonts, { weights } from '@utils/fonts';
import { mq } from '@utils/responsive';

export const Backdrop = styled.svg`
  position: absolute;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const Inner = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 1;
  width: ${(props) => props.isFullWidth ? '100%' : 'auto'};
  padding: 12px ${(props) => props.isIconOnly ? '16px' : '32px'};
  margin: 0;
  align-items: center;
  justify-content: center;
  font-family: ${props => fonts(props.theme.locale).SANS};
  font-size: 16px;
  font-weight: ${weights.BOLD};
  line-height: 20px;
  text-align: center;
  text-decoration: none;
  background-color: ${colors.N0};
  border: 3px solid transparent;
  border-radius: 8px;
  box-shadow: 0;
  cursor: pointer;
  outline: 0;
  user-select: none;

  ${mq.gtsm`
    padding: 16px ${(props) => props.isIconOnly ? '16px' : '48px'};
    font-size: 20px;
    line-height: 24px;
  `}
`;
